<template>
	<div class="overview-filter">
		<div class="search">
			<label :for="id" class="sr-only">Zoeken</label>
			<FormInput
					:id="id"
					v-model.trim="options.search.value"
					:autocomplete="searchAutocomplete"
					:name="id"
					:placeholder="searchPlaceholder"
					autocorrect="off"
					data-testid="search-input"
					inputmode="search"
					type="search"
			/>
		</div>

		<div class="filter-button">
			<BaseButton
					v-if="options.filters.length > 0"
					btn-class="secondary"
					text="Filteren"
					icon-left="filter"
					@click="$emit('showFilterDialog')"
			/>
		</div>

		<OverviewAppliedFilters
				class="applied-filters"
				:options="options"
		/>
	</div>
</template>

<script generic="T extends Record<string, unknown>" lang="ts" setup>
	import {computed, useId} from 'vue';
	import FormInput from '~/components/forms/parts/FormInput.vue';
	import type {FilterOptions} from '~/lib/filter/filterTypes';
	import OverviewAppliedFilters from '~/components/generic/overview/filter/OverviewAppliedFilters.vue';
	import BaseButton from '~/components/generic/buttons/BaseButton.vue';

	defineProps({
		searchPlaceholder: {
			type: String,
			default: 'Zoeken'
		},
		searchAutocomplete: {
			type: String,
			default: 'off'
		}
	});

	defineEmits(['showFilterDialog']);

	const options = defineModel<FilterOptions<T>>('options', {
		required: true
	});

	const id = computed(() => `search-${useId()}`);
</script>

<style scoped>
	.overview-filter {
		padding: 20px 10px 0;
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 10px 20px;
		width: 100%;
	}

	@media screen and (min-width: 480px) {
		.search {
			max-width: 280px;
		}
	}

	.applied-filters {
		overflow: hidden;
		grid-column: 1 / span 2;
	}
</style>
